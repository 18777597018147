.btn-close-panel {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
  width: 36px;
  height: 36px;
  z-index: 1000;
  background-color: white;
}
