@import "global-styles/bootstrap";

.watchlist-list-item {
  button {
    text-align: left;
  }

  strong {
    color: $primary;
  }
}

.btn-email {
  > .icon-email {
    mask-image: url("../../../../assets/other-icons/email-outline.svg");
    mask-size: contain;
    display: block;
    background: #b0b3b8;
  }

  > .icon-email-checked {
    mask-image: url("../../../../assets/other-icons/email-check.svg");
    background: $primary;
  }
}

.btn-email:hover:not(:focus) {
  > .icon-email {
    mask-image: url("../../../../assets/other-icons/email-check.svg");
    background: $primary;
  }

  > .icon-email-checked {
    mask-image: url("../../../../assets/other-icons/email-cross.svg");
    background: $red;
  }
}

.notification-count {
  display: flex;
  align-items: center;

  > small {
    margin-left: -100%;
    color: white;
  }

  > svg {
    fill: #b0b3b8;
  }
}

.notification-count-active {
  > svg {
    fill: $primary;
  }
}

.btn-read-unread {
  &:hover {
    .notification-count {
      > svg {
        fill: $primary;
      }
    }
  }

  &:focus {
    .notification-count {
      > svg {
        fill: #b0b3b8;
      }
    }

    .notification-count-active {
      > svg {
        fill: $primary;
      }
    }
  }
}

.watchlist-table-container {
  background-color: #fff;
  overflow: scroll;
  max-height: 90%;
}
