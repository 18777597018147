.leaflet-draw .leaflet-draw-toolbar a {
  background-size: 400px 40px;

  &.leaflet-draw-draw-polygon {
    background-position: -40px 0;
  }

  &.leaflet-draw-edit-edit {
    background-position: -200px 0;

    &.leaflet-disabled {
      background-position: -280px 0;
    }
  }

  &.leaflet-draw-edit-remove {
    background-position: -240px 0;

    &.leaflet-disabled {
      background-position: -320px 0;
    }
  }
}
