.contextual-layer-popup {
  position: fixed;
  top: 6rem;
  right: 16px;
  background-color: white;
  width: 30rem;
  border: none;
  border-radius: 5px;

  .title {
    padding: 1rem 1rem 0 1rem;
    font-size: large;
    height: auto;
  }
  span {
    padding: 0 2rem 0 0;
  }

  .description {
    padding: 0.5rem 1rem 1rem 1rem;
  }
}
