@import "global-styles/bootstrap";

.wrapper {
  background-color: #e8f7f8;
}

.range-option {
  cursor: pointer;
  &:not(.active):hover {
    color: $gray-600;
  }
}

.date-filter-input {
  min-width: 260px;
}

.date-clear-icon {
  position: absolute;
  right: 2px;
  bottom: 0px;
  top: 7px;
  cursor: pointer;
}
