@import "global-styles/bootstrap";

$datepicker__background-color: $gray-100;
$datepicker__border-color: $border-color;
$datepicker__selected-color: $primary;
$datepicker__border-radius: $border-radius;
$datepicker__font-family: $font-family-sans-serif;
$datepicker__font-size: $font-size-sm;

@import "react-datepicker/src/stylesheets/datepicker.scss";

.react-datepicker__navigation-icon {
  top: 8px;
}
