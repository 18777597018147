@import "global-styles/bootstrap";

.project-pagination-navigation {
  .pagination-heading,
  .active {
    font-weight: bold;
  }

  .page-link {
    padding: 5px;
  }
}
