.bg-login-side {
  background-image: url("../../assets/background-image-1.png");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
}

.login-side-content {
  margin: 4rem auto;
  max-width: 40rem;
}
