.result-tooltip {
  background-color: white;
  font-size: smaller;
}
.moving-tooltip {
  background-color: rgba(255, 255, 255, 1);
  background-clip: padding-box;
  opacity: 0.5;
  font-size: smaller;
}
