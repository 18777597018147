@import "global-styles/bootstrap";
@import "global-styles/map";

// Since the icon is implemented differently for the expanded control layer,
// this mixin allows us to reuse the properties on a different HTML element.
@mixin base-layer-control-icon {
  border-radius: 50%;
  background-color: white;

  // TODO: is there a way to define baseUrl for css?
  background-image: url("../../../../assets/control-icons/base-layer-maps-icon.svg");
  background-size: 30px;

  background-position: center;
  background-repeat: no-repeat;
  box-shadow: $box-shadow; // Should only be applied to icon, not the whole control, so we need to unset this when control is expanded.
}

.leaflet-control-container div {
  z-index: $map-z-index;
}

.leaflet-control.leaflet-control-layers {
  position: absolute; // Needed for visual consistency with UAT
  top: 6px;
  right: 5px;
  padding: 0;
  @include base-layer-control-icon;
}

.leaflet-control-layers-toggle {
  background: unset; // Remove default Leaflet.js icon
}

.leaflet-control.leaflet-control-layers-expanded {
  background-color: unset; // The background colour of the whole expanded container
  background-image: unset; // Prevent icon from appearing in center of expanded container
  border-radius: 0; // Prevent expanded container from being rounded. Not strictly needed since there is no background colour.
  box-shadow: unset; // Prevent shadow from being applied to the whole control (should only be applied to icon)
  margin-left: 10px;
  padding: 0;

  .leaflet-control-layers-toggle {
    display: block; // Stops <a> from disappearing when list is expanded
    background-color: white;
    margin-left: 55px;
    @include base-layer-control-icon;
  }
}

.leaflet-control-layers-list {
  background: white;
  border-radius: 3px;
  padding: 1rem;
  top: 1rem;
  box-shadow: $box-shadow;
  user-select: none;
}
