.topright-button-group {
  top: 0;
  right: 0;
  position: fixed;
  z-index: 10000;
}

.topright-icon {
  height: 40px;
  width: 40px;
  background: white;
}
