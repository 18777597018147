@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap");

$font-family-sans-serif:
  Montserrat,
  system-ui,
  -apple-system,
  "Segoe UI",
  Roboto,
  "Helvetica Neue",
  Arial,
  "Noto Sans",
  "Liberation Sans",
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji";
$h1-font-size: 2.25rem;
$headings-font-weight: 700;
$headings-margin-bottom: 1rem;
$link-decoration: none;
