.sidebar {
  background-image: url("/assets/background-image-1.png");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
}

.sidebar-logo,
.sidebar-icon {
  width: 40px;
}

.sidebar-icon,
.logout-icon {
  opacity: 0.5;

  &:hover,
  &:focus,
  &.active {
    opacity: 1;
  }
}
