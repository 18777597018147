// Custom variables to be used in this file

// Colour
$red: #f14d6e;
$primary: #17adb7;
$dark: #3a414e;
$body-color: #3a414e;
$link-color: #18abb5;
$light-gray: rgba($dark, 0.5);
$background-gray: #efefef;

// Border
$border-radius: 3px;

// Buttons + Forms
$input-btn-padding-y: 0.375rem;
$input-btn-padding-x: 0.5rem;
$btn-font-weight: 700;

// Title heights
$stage-title-height: 36px;
$panel-title-height: 45.5px;

// List group
$list-group-border-width: 0;
$list-group-item-padding-x: 0.5rem;
$list-group-item-padding-y: 0;
$list-group-hover-bg: $background-gray;
$list-group-active-color: $body-color;
$list-group-active-bg: $background-gray;

// Modal
// $modal-inner-padding: 2.5rem;
$modal-content-border-radius: 0;
$modal-header-border-width: 0;
$modal-footer-border-width: 0;
$modal-fade-transform: none;

// Accordion
$accordion-padding-y: 0.5rem;
$accordion-padding-x: 0;
// accordion-border is for the box-shadow when it's open
$accordion-border-width: 0;
$accordion-button-bg: transparent;
$accordion-button-active-bg: transparent;
$accordion-button-active-color: $body-color;

// Box shadow
// offset-x | offset-y | blur-radius | color
$box-shadow: 0 0 2rem $light-gray;

// Bootstrap options https://getbootstrap.com/docs/5.1/customize/options/
// $enable-negative-margins: true;

// Typography go to "./_typography.scss"
