.btn-round {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50% !important;
  padding: 0 !important;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.backward {
  transform: rotate(180deg);
}
