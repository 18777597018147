@import "global-styles/bootstrap";

$nav-width: 72px;
$panel-width: 28rem;
$panel-width-minimised: 3.5rem;

.panel-wrapper {
  .panel-wrapper,
  & + .panel-wrapper {
    border-left: 1px solid $border-color;
  }
}

.panel-body-wrapper {
  width: $panel-width; // Needed for transition
  transition: $transition-collapse-width;
  overflow-x: hidden;

  &.minimised {
    width: $panel-width-minimised;
    background-color: $gray-300;
    overflow: hidden;
    border-left: 1px solid $gray-500;

    .btn-expand {
      fill: $gray-600;
    }

    .collapsed-title {
      display: block;
      padding-top: 1rem;
      font-weight: bold;

      // This will make the text read from top to bottom:
      writing-mode: vertical-rl;

      @-moz-document url-prefix() {
        // This will make the text read from bottom to top (preferred, but
        // currently does not have much support outside of Firefox).
        // https://developer.mozilla.org/en-US/docs/Web/CSS/writing-mode#browser_compatibility
        writing-mode: sideways-lr;
      }
    }
  }

  .btn-collapse {
    float: right;
    margin-top: 0.41rem;
  }
}

.panel-body {
  width: $panel-width;
}

.panel-overlay {
  z-index: 900;
  position: absolute;
  left: calc($panel-width + $nav_width);
  width: calc(100vw - $panel-width - $nav-width);
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.75);
  padding: 2rem;

  .btn-collapse {
    transform: rotate(180deg);
  }
}
