@import "global-styles/bootstrap";
@import "global-styles/map";

.map-controls {
  font-family: $font-family-sans-serif; // leaflet overwrites font family and size
  font-size: $font-size-sm;
  top: 16px; // match base map selector
  right: 59px; // left side of base map selector
  position: absolute;
  margin-right: 15px; // space between base map selector
  z-index: $map-z-index;
}

.map-controls-header-button {
  background-color: white;
  height: 44px; // match base map selector
  float: right;
  border: 0;
  box-shadow: 0 0 2rem rgba(58, 65, 78, 0.5);

  // match colours of other leaflet map buttons
  &.active {
    color: #138991;
  }

  &:hover {
    background-color: #f4f4f4;
    color: #138991;
  }
}

.map-controls-content {
  width: 480px;
  background-color: white;
  box-shadow: 0 0 2rem rgba(58, 65, 78, 0.5);
}
