@import "global-styles/bootstrap";

.badge.selected-item-badge {
  border: 1px solid $background-gray;
  background-color: $background-gray;
  color: $body-color;
  font-weight: 600;

  .clear-button {
    border: 0;
    background-color: transparent;
    color: inherit;
  }
}
