@import "global-styles/map";

.map-tools {
  width: 40px;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: $map-z-index;
  background: #ffffff;
}

.map-tools:hover .hidden-icon {
  display: block;
}

.map-tools-icon {
  background: transparent;
}

.hidden-icon {
  display: none;
}
