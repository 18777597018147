.side-col-footer {
  display: flex-end;
  margin: 1rem auto;
}

.side-col-footer a {
  text-decoration: none;
  color: white;
  font-weight: 400;
}

.side-col-footer li {
  display: inline-block;
  padding: 0 1rem;
}

.fwv-links li:not(:last-child) {
  border-right: 1px solid white;
}
