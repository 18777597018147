#map-container {
  flex-grow: 1;
  height: 100vh;
}

.leaflet-control-scale-line {
  background: #ffffff;
  line-height: 1rem;
}

.leaflet-bottom {
  display: flex;
  flex-direction: row-reverse;
  margin: 0 5rem 1rem 0;
  column-gap: 1rem;
  align-items: flex-end;
}

.leaflet-control-attribution {
  height: 1rem;
}

.leaflet-control-zoom {
  display: flex;
}

.leaflet-touch .leaflet-bar a {
  border-radius: 3px !important;
  border: none;
  font-size: 30px;
  margin-left: 0.5rem;
  width: 40px;
  height: 40px;
  padding: 5px;
}

.leaflet-touch .leaflet-bottom .leaflet-control {
  margin-bottom: 0;
}

.leaflet-touch {
  .leaflet-control-layers,
  .leaflet-bar {
    border: none;
  }
}
