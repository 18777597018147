.clear-icon {
  position: absolute;
  right: 10px;
  bottom: 0px;
  top: 7px;
  cursor: pointer;
}

.btn-delete {
  color: red;
}
